<template>
    <article class="cartas" >
      <header>
        <h2>Cartas de agotados</h2>
        <article>
          <div>            
            <v-text-field
              @input="onInputFiltroCodigo"
              v-model="filtros.codigo"
              :counter="10"              
              label="Código Producto"
              hide-details
              required
            ></v-text-field>
          </div>

          <div>            
            <v-text-field
              v-model="filtros.producto"
              @input="onInputFiltroProducto"
              :counter="10"              
              label="Producto"
              hide-details
              required
            ></v-text-field>
          </div>

          <div>            
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    @input="onInputFiltro"
                    v-model="filtros.fechaDisponibilidad"
                    :counter="10"                    
                    label="Fecha Disponibilidad"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    required
                    ></v-text-field>
                </template>
                <v-date-picker 
                    v-model="filtros.fechaDisponibilidad" 
                    @input="onDatePickerInput2"
                ></v-date-picker>
            </v-menu>
          </div>
        </article>
        <article>
          <div>            
            <v-text-field
              @input="onInputFiltro"
              v-model="filtros.laboratorio"
              :counter="10"              
              label="Laboratorio"
              hide-details
              required
            ></v-text-field>
          </div>
          <div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    @input="onInputFiltro"
                    v-model="filtros.fecha"
                    :counter="10"                    
                    label="Fecha"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    required
                    ></v-text-field>
                </template>
                <v-date-picker 
                    v-model="filtros.fecha" 
                    @input="onDatePickerInput"
                ></v-date-picker>
            </v-menu>
          </div>
          <div>            
            <v-text-field
              @input="onInputFiltro"
              v-model="filtros.carta"
              :counter="10"              
              label="Carta de agotado"
              hide-details
              required
            ></v-text-field>
          </div>
        </article>
      </header>
      <section>
        <article>
          <div>
            <v-btn variant="elevated" color="primary" @click="changeView">
              <v-icon class="me-2">change_circle</v-icon>
            </v-btn>
          </div>
          <div> 
            <v-btn variant="elevated" color="primary" @click="redirectToCarta">
              Nueva carta de agotado
            </v-btn>
          </div>
        </article>        
        <v-container v-if="view">
            <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="20"
            class="elevation-1"
            >
                <template v-slot:headerCell="{ header }">
                    <th
                    :class="['custom-header']"
                    class="text-left"
                    >
                    {{ header.text }}
                    </th>
                </template>
                <template v-slot:item="{ item, index }">
                    <tr :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
                    <td>{{ item.id }}</td>
                    <td>{{ item.laboratorio }}</td>
                    <td>{{ item.fechaCreacion }}</td>
                    <td>
                      <v-chip
                        :color="getColor(item.estado)"
                        dark
                      >
                        {{ getStatus(item.estado) }}
                      </v-chip>
                    </td>
                    <td><v-btn small @click="seePdf(item)">Ver</v-btn></td>
                    <td>{{ item.createdby }}</td>
                    <td>                      
                      <v-btn small @click="editItem(item)">Editar</v-btn>
                      <v-btn small v-if="item.estado == 1" @click="deleteItem(item)">Eliminar</v-btn>
                    </td>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    append-icon="mdi-magnify"
                    ></v-text-field>
                </template>

                <template v-slot:item.estado="{ item }">
                    <v-chip
                    :color="item.estado === 'Disponible' ? 'green' : 'red'"
                    dark
                    >
                    {{ item.estado === 1 ? 'Disponible' : 'No disponible' }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-container>

        <v-container v-if="!view">
            <v-data-table
            :headers="headers2"
            :items="filteredItems2"
            :items-per-page="20"
            class="elevation-1"
            >
                <template v-slot:headerCell="{ header }">
                    <th
                    :class="['custom-header']"
                    class="text-left"
                    >
                    {{ header.text }}
                    </th>
                </template>
                <template v-slot:item="{ item, index }">
                    <tr :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
                    <td>{{ item.id }}</td>
                    <td>{{ item.carta.laboratorio }}</td>
                    <td>{{ item.fecha }}</td>
                    <td>
                      <v-chip
                        :color="getColor2(item.estado)"
                        dark
                      >
                        {{ getStatus2(item.estado) }}
                      </v-chip>
                    </td>
                    <td>{{ item.nombreProducto }}</td>
                    <td>                      
                      <!-- <v-btn small @click="editItem(item)">Editar</v-btn>
                      <v-btn small v-if="item.estado == 1" @click="deleteItem(item)">Eliminar</v-btn> -->
                    </td>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    append-icon="mdi-magnify"
                    ></v-text-field>
                </template>

                <template v-slot:item.estado="{ item }">
                    <v-chip
                    :color="item.estado === 'Disponible' ? 'green' : 'red'"
                    dark
                    >
                    {{ item.estado === 1 ? 'Disponible' : 'No disponible' }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-container>
      </section>
    </article>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import Swal from 'sweetalert2';
  
  
  export default {
    name: 'Index',
    data: () => ({
        search: '',
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Laboratorio', value: 'laboratorio' },
            { text: 'Fecha', value: 'fechaCreacion' },
            { text: 'Disponibilidad', value: 'estado' },
            { text: 'PDF', value: 'nombre', sortable: false },
            { text: 'Creado por', value: 'createdby' },
            { text: 'Acciones', value: 'actions', sortable: false }
        ],
        headers2: [
            { text: 'ID', value: 'id' },
            { text: 'Laboratorio', value: 'laboratorio' },
            { text: 'Fecha', value: 'fechaCreacion' },
            { text: 'Disponibilidad', value: 'estado' },
            { text: 'Nombre', value: 'nombre', sortable: false },
        ],
        items: [            
        ],
        filtros: {
          producto:"",
          codigo:"",
          laboratorio:"",
          fecha:"",
          carta:"",
          fechaDisponibilidad:""
        },   
        menu: false,
        menu2: false,
        tab: null,
        rolesKeycloak: null,
        codigosEmpresas: [],
        dataEmpresas: [],
        usuario: "",
        view: true
    }),
    mounted() {
      this.usuario = this.auth.username;
      this.empresasUsuario();
      this.getCartas();
    },
    computed: {
      ...mapState(["auth", "enterprise"]),
        filteredItems() {
            if (!this.search) {
                return this.items;
            }
            const search = this.search.toLowerCase();
            console.log(search);
            return this.items.filter(item => {
                return (
                item.laboratorio.toLowerCase().includes(search)
                );
            });
        },
        filteredItems2() {
            if (!this.search) {
                return this.productos;
            }
            const search = this.search.toLowerCase();
            console.log(search);
            return this.productos.filter(item => {
                return (
                item.nombreProducto.toLowerCase().includes(search)
                );
            });
        }
    },
  
    methods: {
      ...mapMutations(["setEnterprise"]),
      
      async empresasUsuario() {
        const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
          .then(({ data }) => this.codigosEmpresas = data)
          .catch(err => console.log(err))
        console.log(codEmpresas);
        
        if (codEmpresas && codEmpresas.length > 0) {
          await this.$http.post('msa-setting/api/laboratorio/list', this.codigosEmpresas)
            .then(({ data }) => this.dataEmpresas = data)
            .catch(err => console.log(err))
        }
      },

      changeView() {
        this.view= !this.view;
      },
      getColor(estado) {
        switch (estado) {
          case 1: return 'orange';
          case 2: return 'green'; 
          case 3: return 'blue'; 
          case 4: return 'red'; 
          default: return 'grey'; 
        }
      },
      getStatus(estado) {
        switch (estado) {
          case 1: return 'Sin Asignar';
          case 2: return 'Asignado';
          case 3: return 'Finalizado';
          case 4: return 'Cancelado';
          default: return 'Estado desconocido';
        }
      },

      getColor2(estado) {
        switch (estado) {
          case 1: return 'orange';
          case 2: return 'green'; 
          case 3: return 'red'; 
          case 4: return 'red'; 
          default: return 'grey'; 
        }
      },
      getStatus2(estado) {
        switch (estado) {
          case 1: return 'No disponible';
          case 2: return 'Disponible';
          case 3: return 'Descontinuado';
          case 4: return 'Descontinuado';
          default: return 'Estado desconocido';
        }
      },

      async getCartas() {
        
        this.$http
          .get("msa-administration/api/carta/filtrar")
          .then((result) => {
              console.log(result);
              this.items=result.data;
              this.showSuccessMessage = true;

              const todosLosProductos = this.items.flatMap(item => 
              item.cartaProductos.map(producto => ({
                  ...producto,
                  carta: item
                }))
              );
              this.productos=todosLosProductos;

              // if (response.ok) {
              //   this.uploadStatus = true;
              //   this.selectedFile = null; 
              //   this.$refs.form.reset();  
              // } else {
              //   throw new Error("Error al subir el archivo");
              // }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          }); 
      },

      toEnterprise(enterprise) {
        this.auth.roles = this.rolesKeycloak;
        this.setEnterprise(enterprise)
      },
      seeItem(item) {
        console.log(item);        
        // this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      editItem(item) {
        console.log(item);        
        this.$router.push({ path: '/modules/settings/carta-agotado/edit/'+item.id }); 
      },
      seePdf(item) {
        console.log(item);        
        this.$router.push({ path: '/modules/settings/carta-agotado/edit/'+item.id }); 
      },
      deleteItem(item) {
        console.log(item);  
        Swal.fire({
          title: '¿Estás seguro?',
          text: "¿En realidad deseas eliminar esta carta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {

            this.$http
            .delete("msa-administration/api/carta/"+item.id)
            .then((result) => {
              console.log(result);
              this.items = this.items.filter((it)=> it.id!=item.id);
              Swal.fire(
                'Eliminado',
                'La carta ha sido eliminada correctamente.',
                'success'
              );
            }) 
            .catch((error) => {
                console.log(error?.response?.data);
            }); 

          }
        });      
        // this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      redirectToCarta() {
        this.$router.push({ path: '/modules/settings/carta-agotado/new' }); 
      },
      onDatePickerInput(value) {
        this.filtros.fecha = value;
        this.onInputFiltro(); 
        this.menu = false; 
      },
      onDatePickerInput2(value) {
        this.filtros.fechaDisponibilidad = value;
        this.onInputFiltro(); 
        this.menu2 = false; 
      },
      onInputFiltro() {
        
        this.searchFiltro();
      },

      onInputFiltroCodigo() {
        // if(this.filtros.codigo.length>2){
        this.searchFiltro();
      },

      onInputFiltroProducto() {
        // if(this.filtros.producto.length>2){
        this.searchFiltro();        
      },

      searchFiltro() {

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          console.log('filtros:', this.filtros); 
          this.$http
          .post("msa-administration/api/carta/buscarCartas", this.filtros)
          .then((result) => {
            console.log("Cartas: ", result);
            this.items=result.data;
          });
        }, 3000);
      },
    }
  }
  </script>
  
  <style scoped>
  
  ul, ol {
    list-style: none;
  }

  .v-data-table-header {
    background-color: #f2f2f2; 
    color: #333; 
    font-weight: bold;
    }

    .row-even {
    background-color: #fafafa; 
    }

    .row-odd {
    background-color: #f5f5f5; 
    }

    .v-chip {
    font-size: 0.875rem; 
    }
  
  article.cartas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
  
  
  article.cartas > header {
    border-bottom: .1rem solid #ccc;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  
  article.cartas > header h2 {
    padding-left: 1rem;
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
  
  article.cartas > header > article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  article.cartas > header > article div {
    margin: 0 1rem;
    width: 100%;
  }
  
  article.cartas > header > article div label {
    margin-left: .5rem;
    width: 100%;
  }
  
  article.cartas i {
    margin-right: 0 !important;
  }
  
  article.cartas > section .container{
    width: 100%;
    padding: 12px 0;
  }

  @media (min-width: 960px) {
    article.cartas > section .container{
      max-width: 100%;
    }
  }

  article.cartas > section {
    width: 100%;
  }

  article.cartas > section > article{
    display: flex; 
    justify-content: flex-end;
    width: 98%;   
  }
  
  article.cartas > section > article div{
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  article.cartas > section ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: stretch
  }
  
  </style>